import { Container, Grid, makeStyles, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "#979797",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#02004f',
    fontFamily: "math"
  }));

let fadeEasing = (factor: number = 1) => {
    return {
        enter: 2000 * factor, exit: 3000 * factor
    };
}

let transitionDelay = (delaySecs: number = 1) => {

    return {
         transitionDelay: `${delaySecs * 1000}ms`
    };
};

export { Item, fadeEasing, transitionDelay };

export function Slideshow() {

    // const classes = useStyles();

    let messages: string[] = [
        "Avaa lahja!",
        /* "Hyvää syntymäpäivää Maiski!" */
    ];


    let [msgIndex, setMsgIndex] = useState<number>(0);

    let onClick = () => {
        setMsgIndex(++msgIndex/*  % messages.length */);
        if(msgIndex === messages.length) {

            (window as any).startSnowFlakes("31");
        }
    }

    return (
        <>
            <Stack alignItems="center"
                display="flex"
                style={{'background': "#120f21"}}
                justifyContent="center">
                <Item hidden={msgIndex >= messages.length}>
                    <Item hidden={msgIndex % 2 != 0}>
                        <Fade timeout={fadeEasing(2)} in={true}>
                            <div>
                                <Typography align="center" variant="h2" component="h2">
                                Avaa lahja!
                                </Typography>
                                <Item>
                                    <Button variant="contained"
                                        onClick={onClick}>
                                        Klikkaa
                                    </Button>
                                </Item>
                            </div>
                        </Fade>
                    </Item>
                </Item>
                <Item hidden={msgIndex < messages.length}>
                    <div>
                        <Fade style={transitionDelay(1)} timeout={fadeEasing(2)} in={msgIndex % 2 != 0}>
                            <Typography align="center" variant="h2" component="h2">
                                Hyvää syntymä&shy;päivää Anu!
                            </Typography>
                        </Fade>
                        <Fade style={transitionDelay(3)} timeout={fadeEasing(2)} in={msgIndex % 2 != 0}>
                            <div>

                            <Typography align="center" variant="h4" component="h2">
                                Meille on varattu Kakola Spa -aika 7.10.23 klo 14:00.
                            </Typography>
                            </div>
                        </Fade>
                        <Fade style={transitionDelay(5)} timeout={fadeEasing(2)} in={msgIndex % 2 != 0}>
                            <div>

                            <Typography align="center" variant="h4" component="h2">
                                Sen jälkeen mennään syömään Kakolan Ruusuun...
                            </Typography>
                            </div>
                        </Fade>
                        <Fade style={transitionDelay(7)} timeout={fadeEasing(2)} in={msgIndex % 2 != 0}>
                            <div>

                            <Typography align="center" variant="h4" component="h2">
                                ...ja ollaan yötä Kakolan Hotelissa. ,-)
                            </Typography>
                                <img width="100%" alt="cats"
                                    src={`/photos/kakola.jpg`}
                            />
                            </div>
                        </Fade>
                    </div>
                </Item>
            </Stack>
        </>
    );
}