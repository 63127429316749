import { useEffect, useState } from 'react';
import { useCountdown } from './useCountdown';
import { ShowCounter } from './ShowCounter';

export class CountdownProps {

    public targetDate!: Date;
}

export default function Countdown(props: CountdownProps) {

        const [days, hours, minutes, seconds] =  useCountdown(props.targetDate);

    return (
        <>
        <h3>Lahjan avaami&shy;seen jäljellä:</h3>
        <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            />
        </>
    );

}