// https://blog.greenroots.info/how-to-create-a-countdown-timer-using-react-hooks

import StyledPaper from './StyledPaper';

export class ShowCounterProps {

    public days!: number;
    public hours!: number;
    public minutes!: number;
    public seconds!: number;
}

export const ShowCounter = (props: ShowCounterProps) => {
    return (
      <StyledPaper>
        <div>
          <div style={{fontSize: 50}}>{props.days} päivää</div>
          <div style={{fontSize: 40}}>{props.hours} tuntia</div>
          <div style={{fontSize: 30}}>{props.minutes} minuuttia</div>
          <div style={{fontSize: 20}}>{props.seconds} sekuntia</div>
        </div>
      </StyledPaper>
    );
  };